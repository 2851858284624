
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { useAuth } from 'context/authContext';
import { countryFromHost, isPerformanceRoute, localeFromHost, Permalink } from 'helpers';
import { basicSEO } from 'helpers/jsx';
import useTranslation from 'next-translate/useTranslation';
import I18nProvider from 'next-translate/I18nProvider';
import Head from 'next/head';
import AppLink from '../../components/AppLink/AppLink';
import Layout from '../../components/Layout';
import styles from './index.module.scss';
import { locales, defaultLocale } from 'i18n';
import { GetStaticPropsResult, GetStaticPropsContext } from 'next';
import { StaticPathParams } from 'typings/staticPaths';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

interface Props {
  countryCode: string;
  locale: string;
}

const i18n = locales.map((l) => ({
  locale: l,
  common: require(`localization/${l}/common.json`),
  seo: require(`localization/${l}/seo.json`),
}));

const Content = ({ countryCode, locale }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { asPath } = useRouter();

  const [title, description] = useMemo(() => {
    let t = 'common:not_found.title';
    let desc = 'common:not_found.description';

    if (isPerformanceRoute(asPath)) {
      t = 'common:performance_not_found.title';
      desc = 'common:performance_not_found.description';
    }

    return [t, desc];
  }, [asPath]);

  return (
    <Layout countryCode={countryCode}>
      <Head>{basicSEO(t('seo:404_title'), t('seo:404_description'))}</Head>
      <main className={styles.notFound}>
        <div className="section-wrapper">
          <div className="heading">
            <h1>{t(title)}</h1>
          </div>
          <p>{t(description)}</p>
          <AppLink href={Permalink.root(user, locale)}>
            <a className="btn btn-primary">{t('common:not_found.btn')}</a>
          </AppLink>
        </div>
      </main>
    </Layout>
  );
};

const Page404 = (): JSX.Element => {
  const { locale, isUserLoaded, user } = useAuth();

  if (!isUserLoaded) {
    return null;
  }

  const _locale = user?.language ?? localeFromHost(window.location.host);
  const countryCode = countryFromHost(window.location.host);
  const { common, seo } = i18n.find((c) => c.locale === _locale) ?? {};

  return (
    <I18nProvider lang={_locale} namespaces={{ common, seo }}>
      <Content countryCode={countryCode} locale={_locale} />
    </I18nProvider>
  );
};

 async function _getStaticProps(
  ctx: GetStaticPropsContext<StaticPathParams>,
): Promise<GetStaticPropsResult<unknown>> {
  ctx.locale = defaultLocale;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return { props: {} };
}

export default Page404;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  